require('./bootstrap');

import Swal from 'sweetalert2';


window.Swal = Swal;

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  /*onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  } */
})

Livewire.on('toast', message => {
    Toast.fire(message.text,'', message.type);

})


Livewire.on('popup', message => {
    Swal.fire(message.text)

})
